<script lang="ts" setup>
import { BadgeIcon, CheckmarkIcon, DangerTriangleIcon, InfoCircleIcon } from '@cfgtech/icons'

const props = defineProps<{ variant: 'info' | 'warning' }>()

const typeClass = computed(() => {
  switch (props.variant) {
    case 'info':
      return 'text-grey-400 border-grey-400'
    case 'warning':
      return 'text-red border-red'
    case 'success':
      return 'text-success border-success'
    case 'error':
      return 'text-red border-red'
    default:
      return 'text-grey-400 border-grey-400'
  }
})

const typeIcon = computed(() => {
  switch (props.variant) {
    case 'info':
      return BadgeIcon
    case 'warning':
      return DangerTriangleIcon
    case 'error':
      return InfoCircleIcon
    case 'success':
      return CheckmarkIcon
    default:
      return BadgeIcon
  }
})
</script>

<template>
  <div
    class=" flex w-full flex-row items-center rounded-xl border border-solid px-2 py-4"
    :class="typeClass"
  >
    <div class="w-10">
      <component :is="typeIcon" class=" text-[1.5rem]" />
    </div>

    <div class=" pl-2">
      <slot />
    </div>
  </div>
</template>
